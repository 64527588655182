.bg-warning-light {
    background-color: #fff3cf;
  }

  .bg-danger-light {
    background-color: #fde8e9;
  }

  .bg-secondary-light {
    background-color: #ebeeff;
  }

  .bg-success-light {
    background-color: #def9e4;
  }
  .bg-tertiary-light {
    background-color: #e7f2f9;
  }