// Set the full page height so we can stick the footer to the bottom
@import 'bootstrap/scss/mixins';
@import 'css/bootstrap-variables';
@import './unothodox';

html,
body {
    height: 100%;
}

button.calendar,
button.calendar:active {
    width: 2.75rem;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=') !important;
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
}

.cursor-pointer {
    cursor: pointer;
}

.color-empty-data {
    color: #adb5bd;
}

.has-no-error {
    margin-bottom: $form-spacing-height;
}

input.form-control.ng-invalid.ng-touched {
    border: 2px solid $danger;
    border-radius: 11px;
    color: $danger !important;
    background-color: $danger-bg;
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

::-webkit-scrollbar-track {
    background: $grayscale-bg;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #97a1bb;
    border-radius: 10px;
}

.btn-transparent,
.btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent:active {
    box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px 0 inset;
    transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

.form-floating {
    label {
        left: auto;
    }
    textarea.ng-invalid.ng-touched,
    input.ng-invalid.ng-touched {
        border: 2px solid $danger;
        border-radius: 11px;
        color: var(--bs-danger-text);
        background-color: var(--bs-danger-bg-subtle);
    }

    input.ng-invalid.ng-touched + label {
        color: $danger;
    }

    input:disabled + label {
        color: $gray-500;
    }

    label::after {
        background-color: unset !important;
    }
}

.form-control:disabled {
    background-color: rgba($color: #e9ecef, $alpha: 0.4);
    border-color: rgba($color: #dee2e6, $alpha: 0.4);
    box-shadow: none;
    color: $gray-500;
}

.form-floating > .form-control:disabled ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
}

input:focus-visible {
    outline: none;
}

.form-control:focus-visible {
    outline: none;
}

.form-floating.dropdown:focus {
    box-shadow: none !important;
}

// For dark theme
.form-control,
.form-control:focus {
    background-color: var(--bs-body-bg);
}

// For datepicker
.ngb-dp-header {
    background-color: var(--bs-light-subtle) !important;
}

.ngb-dp-weekdays {
    background-color: var(--bs-light-subtle) !important;
}

.form-select {
    background-color: var(--bs-body-bg);
}

.ngb-dp-day:hover {
    color: black;
}

// For modal
.modal-header,
.modal-body,
.modal-footer {
    border-color: var(--bs-light-bg-subtle);
}

@include media-breakpoint-between(xs, sm) {

    button, li, a, label.form-label, td, th, .list-group-item, p, div {
        font-size: 0.8rem !important;
    }

    .h4, h4 {
        font-size: 1.15em !important;
    }

    .h3, h3 {
        font-size: 1.25em !important;
    }

    .h2, h2 {
        font-size: 1.35em !important;
    }

    .h1, h1 {
        font-size: 1.45em !important;
    }
    
    .btn-primary, .btn-secondary  {
        width: fit-content;
        font-size: $btn-font-size-sm;  
    }

}

//Styles for FullCalendar
.fc-daygrid-day-number {
    font-weight: bold;
}
